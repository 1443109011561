<template>
  <div id="app">
    <Nav :mode-str="mode" />
    <router-view/>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue"
export default {
    components:{Nav},
    name: 'App',
    data(){
        return{
            mode:'horizontal'
        }
    },
    computed:{
        path(){
            return this.$store.state.path;
        }
    },
    watch:{
        path(value){//路由跳转全网监控
            console.log('path',value)
            this.$router.push({
                path:value.path,
                query:value.query
            })
        }
    },
    mounted(){
        let width = window.innerWidth;
            this.$store.commit('set_width', width)
            if(width<768){
                this.mode = 'vertical'
            }else{
                this.mode = 'horizontal'
            }
        const _this = this
        window.onresize = function(){
            let width = window.innerWidth;
            _this.$store.commit('set_width', width)
            if(width<768){
                _this.mode = 'vertical'
            }else{
                _this.mode = 'horizontal'
            }
        }
        this.$store.commit('set_Module',this.$router.history.pending.matched[0].path)
        this.$store.commit('set_path',{path:this.$router.history.pending.fullPath,query:{}})
        
    },

}
</script>

<style>

body{
    margin:0;
}
html{
    /* font-size:14px; */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top:50px;
  background: rgb(246 246 246);
}
p{
    margin:0;
}
@media screen and ( min-width:1200px){
    html{
        font-size:20px!important;
    }
}
@media screen and ( max-width:768px){
    html{
        font-size:14px!important;
    }
}
/* /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #284c91!important;
} */

</style>

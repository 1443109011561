import Vue from 'vue'
import Router from 'vue-router'
import LayoutContent from '@/components/LayoutContent'
Vue.use(Router)

let lsRouter = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'home',
        component: () => import('./views/Home.vue')
        },
        {
        path: '/goin',
        name: 'goin',
        component: LayoutContent,
        meta:{
            title:'走进历思',
        },
        children:[
            // {
            // path:'speech',
            // name:'speech',
            // component: () => import('./views/Goin/Speech.vue'),
            // meta:{
            //     title:'主席致辞',
            //     },
            // },
            {
            path:'introduct',
            name:'introduct',
            component: () => import('./views/Goin/Introduct.vue'),
            meta:{
                title:'集团简介',
                img:'goin1.png'
                },
            },
            {
            path:'plan',
            name:'plan',
            component: () => import('./views/Goin/Plan.vue'),
            meta:{
                title:'发展历程',
                img:'goin1.png'
                },
            },
            // {
            // path:'innovate',
            // name:'innovate',
            // component: () => import('./views/Goin/Innovate.vue'),
            // meta:{
            //     title:'研发创新',
            //     img:'goin1.png'
            //     },
            // },
            {
            path:'honor',
            name:'honor',
            component: () => import('./views/Goin/Honor.vue'),
            meta:{
                title:'企业荣誉',
                img:'goin1.png'
                },
            },
            {
            path:'connect',
            name:'connect',
            component: () => import('./views/Goin/Connect.vue'),
            meta:{
                title:'联系我们',
                img:'goin1.png'
                },
            },
        ]
        },
        {
            path: '/news',
            name: 'news',
            component: LayoutContent,
            meta:{
                title:'新闻中心',
                
            },
            children:[
                {
                path:'ls',
                name:'ls',
                component: () => import('./views/News/Ls.vue'),
                meta:{
                    title:'历思动态',
                    img:'news1.png'
                    },
                },
                {
                path:'ls_detail/:id',
                name:'ls_detail',
                component: () => import('./views/News/LsDetail.vue'),
                meta:{
                    title:'历思动态',
                    ignore:true,
                    img:'news1.png'
                    },
                },
                {
                path:'news',
                name:'news',
                component: () => import('./views/News/News.vue'),
                meta:{
                    title:'媒体聚焦',
                    img:'news2.jpg'
                    },
                },
                {
                path:'news_detail/:id',
                name:'news_detail',
                component: () => import('./views/News/NewsDetail.vue'),
                meta:{
                    title:'媒体聚焦',
                    ignore:true,
                    img:'news2.jpg'
                    },
                },
                {
                    path:'video',
                    name:'video',
                    component: () => import('./views/News/Video.vue'),
                    meta:{
                        title:'视频中心',
                        img:'news3.png'
                        },
                },
            ]
            },
            {
                path: '/domain',
                name: 'domain',
                component: LayoutContent,
                meta:{
                    title:'集团产业',
                    
                },
                children:[
                    {
                    path:'ls_authenticate',
                    name:'ls_authenticate',
                    component: () => import('./views/Domain/Ls.vue'),
                    meta:{
                        title:'司法鉴定',
                        img:'authenticate1.jpg'
                        },
                    },
                    {
                    path:'ls_kunsheng',
                    name:'ls_kunsheng',
                    component: () => import('./views/Domain/KS.vue'),
                    meta:{
                        title:'检验检测',
                        img:'authenticate1.jpg'
                        },
                    },
                    {
                    path:'ls_kunming',
                    name:'ls_kunming',
                    component: () => import('./views/Domain/KM.vue'),
                    meta:{
                        title:'智慧装备',
                        img:'authenticate1.jpg'
                        },
                    },
                    {
                    path:'ls_value',
                    name:'ls_value',
                    component: () => import('./views/Domain/Value.vue'),
                    meta:{
                        title:'价值评估',
                        img:'authenticate1.jpg'
                        },
                    },
                ]
            },
            {
                path: '/duty',
                name: 'duty',
                component: LayoutContent,
                meta:{
                    title:'社会责任',
                    
                },
                children:[
                    // {
                    // path:'system',
                    // name:'system',
                    // component: () => import('./views/Duty/System.vue'),
                    // meta:{
                    //     title:'责任体系',
                    //     img:'duty1.jpg'
                    //     },
                    // },
                    {
                    path:'public',
                    name:'public',
                    component: () => import('./views/Duty/Public.vue'),
                    meta:{
                        title:'社会公益',
                        img:'duty2.jpg'
                        },
                    },
                    {
                    path:'public_detail/1',
                    name:'public_detail/1',
                    component: () => import('./views/Duty/PublicDetail1.vue'),
                    meta:{
                        title:'社会公益',
                        img:'duty2.jpg',
                        ignore:true
                        },
                    },
                    {
                    path:'public_detail/2',
                    name:'public_detail/2',
                    component: () => import('./views/Duty/PublicDetail2.vue'),
                    meta:{
                        title:'社会公益',
                        img:'duty2.jpg',
                        ignore:true
                        },
                    },
                    {
                    path:'trust',
                    name:'trust',
                    component: () => import('./views/Duty/Trust.vue'),
                    meta:{
                        title:'信任认可',
                        img:'duty3.jpg'
                        },
                    },
                    
                ]
            },
            {
                path: '/talents',
                name: 'talents',
                component: LayoutContent,
                meta:{
                    title:'人力资源',
                    
                },
                children:[
                    // {
                    // path:'idea',
                    // name:'idea',
                    // component: () => import('./views/Talents/Idea.vue'),
                    // meta:{
                    //     title:'人才理念',
                    //     img:'talents1.jpg'
                    //     },
                    // },
                    {
                      path:'invite',
                      name:'invite',
                      component: () => import('./views/Talents/Invite.vue'),
                      meta:{
                        title:'人才招聘',
                        img:'talents3.jpg',
                        },
                    },
                    {
                    path:'develop',
                    name:'develop',
                    component: () => import('./views/Talents/Develop.vue'),
                    meta:{
                        title:'工作环境',
                        img:'talents4.jpg'
                        },
                    },
                    {
                    path:'develop_detail/1',
                    name:'develop_detail/1',
                    component: () => import('./views/Talents/DevelopDetail1.vue'),
                    meta:{
                        title:'工作环境',
                        img:'talents4.jpg',
                        ignore:true
                        },
                    },
                    {
                      path:'activity',
                      name:'activity',
                      component: () => import('./views/Duty/Activity.vue'),
                      meta:{
                          title:'社团活动',
                          img:'duty3.jpg'
                          },
                      },
                    // {
                    // path:'statement',
                    // name:'statement',
                    // component: () => import('./views/Talents/Statement.vue'),
                    // meta:{
                    //     title:'招聘声明',
                    //     img:'talents4.jpg'
                    //     },
                    // },
                ]
            },
    ]
})
lsRouter.afterEach((to,form,next)=>{
    console.log(to,form,next)
    document.documentElement.scrollTop = 0;//回到顶部
})
    // 判断是否需要登录权限 以及是否登录
    // lsRouter.beforeEach((to, from, next) => {
    //   // 判断是否需要登录权限
    //   if (to.matched.some(res => res.meta.requireAuth)) {
    //     // 判断是否登录
    //     if (sessionStorage.getItem('token')) {
    //       next()
    //     } else {
    //       // 没登录则跳转到登录界面
    //       next({
    //         path: '/login',
    //         query: {
    //           redirect: to.fullPath
    //         }
    //       })
    //     }
    //   } else {
    //     next()
    //   }
    // })

export default lsRouter
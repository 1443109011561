<template>
    <div class="footer-box center">
        <div class="box1">
            <div class="content-box">
                <!-- <div class="title">联系我们</div>
                <el-row class="form-box" :gutter="20">
                    <el-col :xs="24" :sm="10">
                        <el-input placeholder="请输入你的邮箱"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="10">
                        <el-input placeholder="请输入你的留言"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="4" class="btn-box">
                        <el-button type="primary" class="btn">发送</el-button>
                    </el-col>
                </el-row> -->
                <div class="line"></div>
                <div class="link-box">
                    <div class="link-item" v-for="item in linkData" :key="item.path">
                        <a class="title2">{{item.meta.title}}</a>

                        <!-- <router-link v-for="route in item.children" :key="route.path" :to="item.path+'/'+route.path" class="link-text">{{route.meta.title}}</router-link> -->
                        <span @click="go(item.path+'/'+route.path,item.path)" v-for="route in item.children" :key="route.path"  class="link-text">{{route.meta.title}}</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="box2 center">
            <p>地址：福建省厦门市湖里区湖里大道33号7层</p>
            <p>版权所有： 厦门历思科技服务有限公司|备案号 闽ICP备2021011987号-1</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
    },
    data(){
        return{
            linkData:[]
        }
    },
    mounted(){
        let data = this.$router.options.routes.filter(item=>item.path!='/')
        data = data.map(item=>{
            return {...item,children:item.children.filter(route=>!route.meta.ignore)}
        })
        this.linkData = data
        console.log(data)
    },
    methods:{
      go(path,Module){
        this.$store.commit('set_path',{path:path,query:{}})
        this.$store.commit('set_Module',Module)
      }
    }
}
</script>
<style scoped src="@/common.css"></style>
<style scoped>
@media screen and (max-width:1200px){
    .content-box{
        width:100vw;
    }
    
}
@media screen and (max-width:768px){
    .form-box{
        height: 158px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .btn-box{
        display: flex;
        justify-content: end;
       
    }
    .btn-box .el-button{
        width:100px;
    }
    
}
.link-box{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
}
.link-item{
    /* width: 14vw; */
    margin-top: 20px;
    /* min-width: 156px; */
    /* max-width: 300px; */
    display:flex;
    flex-direction:column;
}
.title2{
    font-size: 22px;
    margin-bottom: 17px;
    text-align: left;
    display: block;
    text-decoration: unset;
    color: #000;
}
.link-text{
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    height: 26px;
    display: block;
    text-decoration: unset;
    color: #000;
}
.link-text:hover{
    color:#003677;
}
.form-box{
}
.content-box{
    width:80vw;
}
.box1{
    /* background-color:#f4f4f4; */
    width:100%;
    margin:0 auto;
    padding: 4rem 0;
    justify-content: center;
    display: flex;
    max-width: 1600px;
}
.box1 .title{
    font-size: 33px;
    text-align: left;
    padding: 20px 0;

}
.box2{
    padding: 1rem 0;
    background-color: #284c91;
    color: #fff;
    font-size: 0.8rem;
    flex-direction: column;
    width: 100%;
}
.footer-box{
    flex-direction: column;
    width:100%;
    background: #fff;
}
/deep/ .el-button{
    border-color:#284c91;
    background-color:#284c91;
}
.line{
    width:100%;
    height:1px;
    background-color: #dcdfe6;
    margin: 46px 0px;
}
</style>
<template>
    
        <el-row class="main">
            <div class="bg-black top-box">
            <el-col class="hidden-xs-only center" :sm="6" :md="8" :lg="12" :xl="12">
                <div @click="goHome" class="center">
                    <img v-lazy="logo" class="logo"/>
                    <div class="center vertical top-title" >
                        <p class="text1">历思集团</p>
                        <p class="text2">LISI GROUP</p>
                    </div>
                </div>
            </el-col>
            <el-col  :sm="18"  :md="16" :lg="12" :xl="12">
                <div class="sm-box">
                    <div v-if="mode == 'vertical'" style="display:flex;justify-content:space-between">
                        <div @click="goHome" class="center" >
                            <img v-lazy="logo" class="logo"/>
                            <div class="center vertical top-title" >
                                <p class="text1">历思集团</p>
                                <p class="text2">LISI GROUP</p>
                            </div>
                        </div>
                        <div  class=" center menu-box">
                            <img v-lazy="menu" @click="show = !show" class="menu"/>
                        </div>
                    </div>
                    <transition name="fade">
                        <el-menu v-if="show || mode=='horizontal'" :mode="mode" text-color="#fff"  
                        menu-trigger="hover"
                        :default-active="default_active"
                        :router="false"
                        @select="selectEvent"
                        
                        active-text-color="#409eff"
                        background-color="#222222">
                            
                                <el-submenu :popper-append-to-body="true" v-for="route in router" :index="route.path" :key="route.name">
                                    <template slot="title">{{route.meta.title}}</template>
                                    <el-menu-item v-for="item in route.children" :key="item.name" :index="route.path+'/'+item.path">{{item.meta.title}}</el-menu-item>
                                </el-submenu> 

                        </el-menu>
                    </transition>
                </div>
            </el-col>
            </div>
        </el-row>
        
        
    
</template>
<script>
import logo from "@/assets/logo.jpg"
import menu from "@/assets/menu.png"
export default {
    // props:['mode'],
    props:{
        modeStr:{
            type:String,
        }
    },
    data(){
        return{
            logo:logo,
            show:false,
            menu:menu,
            router:[],
            default_active:'',//当前菜单
        }
    },
    mounted(){
        console.log(this.$router)
        let data = this.$router.options.routes.filter(item=>item.path!='/')
        data = data.map(item=>{
            return {...item,children:item.children.filter(route=>!route.meta.ignore)}
        })
        // data = data[0].children.filter(item=>!item.meta.ignore)
        this.router = data
        console.log(data,this.$route)
        this.default_active = this.$router.history.pending.fullPath
    },
    computed: {
        mode: function(){
            return this.modeStr
        },
        path(){//确定当前选中的菜单
            return this.$store.state.path.path
        }
    },
    watch:{
        path(value){
            this.default_active = value
        }
    },
    methods:{
        goHome(){
            this.$store.commit('set_path',{path:'/',query:{}})
        },
        selectEvent(index,indexPath){
            console.log(index,indexPath)
            this.show = false;
            this.$store.commit('set_Module',indexPath[0])
            this.$store.commit('set_path',{path:indexPath[1],query:{}})
        }
    }
}
</script>
<style scoped src="@/common.css"></style>
<style scoped>
/deep/ .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
    color:#409EFF!important;
}
/deep/.el-submenu__title{
    font-size:1rem!important;
}
.center{
    justify-content: flex-start;
    padding-left: 1rem;
}
.logo{
    width:3rem;
    height:3rem;
    border-radius:50%
}
.top-title{
    margin-left:20px;
    color: #52b0f3;
}
.top-title .text1{
    letter-spacing:4px;
    font-size: 22px;
    font-weight: bold;
    width:max-content;
    margin-bottom: 10px;
}
.top-title .text2{
    font-size: 14px;
    font-weight:bold;
    letter-spacing:2px;
}
.el-menu.el-menu--horizontal{
    border-bottom:0;
}
.top-box{
    display:flex;
    width:100%;
}
@media screen and (max-width: 768px){
    /deep/ .el-submenu__title{
        width:100%;
        font-size: 14px;
    }
    /deep/ .el-menu{
        border-right-color:#000
    }
    .sm-box{
        display: block!important;
    }
    .logo{
        width: 50px;
        height: 50px;

    }
}
@media screen and (max-width: 945px){
    .center{
        padding:0;
    }
}
.menu-box{
    padding-right:20px;
    text-align:right;
}
.menu{
    width:30px;
    height:30px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.sm-box{
    display: flex;
    justify-content: end;
    margin: 8px 0;
}
.main{
    position: fixed;
    top: 0;
    z-index: 6;
    width: 100vw;
    max-width: 100%;
}


</style>
<style>


</style>
<template>
    <div>
    <el-image 
        :style="{width: width, height: height}"
        :src="src" 
        :key="src"
        :preview-src-list="srcList">
    </el-image>
    <slot></slot>
    </div>
</template>
<script>
export default {
    props:{
        src:{
            type:String
        },
        srcList:{
            type:Array
        },
        width:{
            default:'100%',
        },
        height:{
            default:'100%',
        },
    },
    data(){
        return{
        }
    },
    methods:{
    }
}
</script>
<style scoped src="@/common.css"></style>
<style scoped>
</style>
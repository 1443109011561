<template>
    <div class="image-box">
        <img class="img" v-lazy="require('../assets/Limage/'+src)" :alt="alt"/>
        <div class="img-text">{{str}}</div>
    </div>
</template>
<script>
export default {
    // props:['mode'],
    props: {
        srcStr:{
            type:String,
        },
        alt:{
            type:String,
        },
    },
    data(){
        return{
        }
    },
    mounted(){
        console.log(this.$route)
    },
    computed: {
        src: function(){
            return this.$route.meta.img
        },
        str: function(){
            return this.$route.meta.title
        }
    },
}
</script>
<style scoped src="@/common.css">

</style>
<style scoped>
.image-box{
    position: relative;
    overflow:hidden;
    margin-top: 66px;
}
.img{
    /* opacity: 0; */
    width: 100%;
    height:600px;
    animation: imgAnimation 25s linear infinite;
    
}
.img-text{
    position: absolute;
    bottom: 4rem;
    left: 3rem;
    font-size: 2rem;
    color: #fff;
}
@keyframes imgAnimation {
    0%{transform:scale(1)}
    20%{transform:scale(1.1)}
    40%{transform:scale(1.2)}
    60%{transform:scale(1.3)}
    80%{transform:scale(1.2)}
    100%{transform:scale(1)}
}
@media screen and ( max-width: 1200px){
    .img{
        height:40vw;
    }
}

</style>
<template>
    <div class="">
        <Limage></Limage>
        <Tab></Tab>
        <router-view></router-view>
        <Footer></Footer>
        <Side></Side>
    </div>
</template>
<script>
import Limage from "@/components/Limage";
import Footer from "@/components/Footer";
import Tab from "@/components/Tab";
import Side from "@/components/Side";
export default {
    components:{
        Limage,
        Footer,
        Side,
        Tab
    },
    data(){
        return {
        }
    },
    
}
</script>
<style scoped>

</style>
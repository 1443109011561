<template>
    <div class="image-box">
        <div>
            <img class="img" @click="event('wx')" v-lazy="require('../assets/home/wx.png')"/>
            <img class="img" @click="event('tip')" v-lazy="require('../assets/home/tip.png')"/>
        </div>
        <div class="ecode" v-if="show">
            <i class="el-icon-circle-close close" @click="handleClose"></i>
            <img  class="wx-code" v-lazy="src" />
        </div>
        <!-- <el-dialog
        :title="title"
        :visible.sync="show"
        width="172px"
        :append-to-body ="true"
        :model="false"
        custom-class="wx"
        :before-close="handleClose">
        <img v-if="now=='wx'" class="wx-code" v-lazy="src" />
        
        </el-dialog> -->
    </div>
</template>
<script>
import src from "@/assets/component/wxCode.jpg"
export default {
    data(){
        return{
            show:false,
            title:'',
            now:'',
            src:src
        }
    },
    methods:{
        handleClose(){
            this.show = false
        },
        event(type){
            this.now = type
            
            switch(type){
                case 'wx':
                    this.show = !this.show;
                break;
                case 'tip':
                    this.$store.commit('set_Module','/goin')
                    this.$store.commit('set_path',{path:'/goin/connect',query:{}})
                break;
            }
        }
    }
}
</script>
<style scoped src="@/common.css"></style>
<style scoped>
.close{
position: absolute;
    right: -1rem;
    top: -1rem;
    /* background: #fff; */
    font-size: 2rem;
}
.ecode{
    position:fixed;
    left: 6rem;
    top: calc(50vh - 110px);
    padding:2rem;
    background:rgba(0,0,0,0.4)
}
.image-box{
    width: 65px;
    position: fixed;
    left: -2px;
    top: calc(50vh - 50px);
}
/deep/.wx .el-dialog__body{
    text-align:center;
    padding:0 20px;
}
.wx-code{
    width:113px;
}
/deep/ .wx .el-dialog__headerbtn .el-dialog__close{
    font-size: 32px;
    border-radius: 50%;
    border: solid;
    position: relative;
    top: -31px;
    right: -27px;
}
/deep/ .wx .el-dialog__header{
padding: 0 20px 10px;
}
.wx{
    margin-top: calc(50vh - 100px)
}
</style>
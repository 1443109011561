import { render, staticRenderFns } from "./Limage.vue?vue&type=template&id=b4631910&scoped=true&"
import script from "./Limage.vue?vue&type=script&lang=js&"
export * from "./Limage.vue?vue&type=script&lang=js&"
import style0 from "@/common.css?vue&type=style&index=0&id=b4631910&scoped=true&lang=css&"
import style1 from "./Limage.vue?vue&type=style&index=1&id=b4631910&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4631910",
  null
  
)

export default component.exports
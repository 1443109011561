import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import App from './App.vue';
import VueRouter from 'vue-router'
import router from './router.js'
import store from './store'
import Title from './components/Title'
import ImagePreview from './components/ImagePreview'
import pgP from './components/pg/pg-p'
import More from './components/More'
import 'animate.css'
import 'hover.css'
import {WOW} from "wowjs"
import VueLazyload from 'vue-lazyload'  // 引入这个懒加载插件
// Vue.use(VueLazyload) // 直接使用
Vue.use(VueLazyload, { // 添加自定义选项
  preLoad: 1.3,
  error: require('./assets/error.png'), // 加载错误时候的图片
  loading: require('./assets/loading.gif'), // 加载中的图片
  attempt: 1,
  listenEvents: [ 'scroll' ],
})
Vue.prototype.$wow = WOW
Vue.use(VueRouter)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.component('Title',Title)
Vue.component('ImagePreview',ImagePreview)
Vue.component('Pgp',pgP)
Vue.component('More',More)
Vue.mixin({
    mounted() {
        this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        const wow = new this.$wow({
            live: false
        })
        wow.init()
        })
    },
})
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
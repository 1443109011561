<template>
    <div class="main">
        <slot></slot>
    </div>
</template>
<script>
export default {
    // props:[
    //     'title'
    // ],
}
</script>
<style scoped src="@/common.css"></style>
<style scoped>
.main{
    font-size:1.8rem;
    color:#284c91;
    /* margin:0 auto;
    text-align:center; */
    margin: 4rem 0 2rem 0!important;
}
</style>
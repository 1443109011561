import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

let store = new Vuex.Store({
    state:{
        Module: '',//当前模块 如/goin
        width:'',//宽度
        path:{path:'',query:{}},//当前路径
        lsData:[
            {id:1,time:'2021.01.08',content:[
              ['text','2015年7月30日，福建历思司法鉴定所在漳州市中级人民法院通过现场与远程视频方式为全省人民法院、人民检察院、环保局等相关单位作《环境损害-法-鉴定评估》培训。'],
              ['img','1-1.jpg'],
              ['text','交流会上，全体党员共同深入学习领会党的十九届五中全会精神，并就相关主题进行交流研讨。全体党员讨论后，要以五中全会精神为指引，探索建立税收和司法鉴定相结合新形式，为厦门经济高质量发展贡献重要的力量。'],
              ['img','1-2.jpg'],
              ['img','1-3.jpg'],
              ['img','1-4.jpg'],
              ['text','会上还特别邀请了著名笔迹心理学研究与运用专家、文书司法鉴定人金一贵老师，围绕“从书写了解人的个性及心理-字如其人、字变人变”主题，为全体党员进行了一场生动精彩的讲座，让大家感受笔迹心理学的奇妙力量。'],
              ['img','1-5.jpg'],
              ['text','经过深入的研讨交流，双方都一致认为，作为党员更应该发挥好先锋模范作用，认真学习贯彻五中全会精神，结合实际工作，将党建工作与企业业务进行深度融合。希望在今后的工作中，双方能够继续加强沟通交流，进一步拓展党建工作新思路，谋划党建工作新发展，不断推进双方党建工作迈上新台阶。'],
            ],title:'厦门市税务局稽查局办公室和检查三科党支部与历思共建学习交流会',},
            {id:2,time:'2021.03.25',content:[
              ['text','近日，《福建省污染地块调查评估、治理修复单位专业机构推荐名录（2021年度）》已经发布。'],
              ['text','历思物证科技产研体系成员企业——厦门堃晟检测技术有限公司（以下简称“堃晟”）成功入选调查评估类、方案编制类、效果评估类等类目推荐机构。'],
              ['img','2-1.png'],
              ['img','2-2.png'],
              ['img','2-3.png'],
            ],title:'堃晟入选福建省污染地块调查评估、治理修复单位专业机构推荐名录',},
            {id:3,time:'2021.03.25',content:[
              ['text','热烈祝贺厦门历思科技服务有限公司（以下简称“历思”）通过了“国家高新技术企业”认定，由此正式迈入国家高新技术企业行列。'],
              ['img','3-1.jpg'],
              ['text','本次被评定为国家高新技术企业，将进一步推动公司自主创新、自主研发的进程，同时也是历思发展史上的又一个里程碑。'],
              ['img','3-2.png'],
              ['text','（历思物证科技产研体系架构图）'],
              ['text','秉持“智慧物证，可信鉴定”的理念，历思长期致力于发展智慧物证科技，为社会提供可信鉴定服务。立足原发性创新，通过综合服务与配套软硬件产品的结合，历思在物证与鉴定领域建立了独特的竞争优势。'],
              ['text','今后，整个历思科技产研体系将持续创新，推动IT、刑侦等当代科技在物证与鉴定领域的融合、创新，以此获得竞争力与发展机遇。'],
              ['img','3-3.jpg'],
            ],title:'喜讯！热烈祝贺历思科技通过高新技术企业认定！'},
        ],
        newsData:[
            {id:1,time:'2018.01.14',title:'笔迹专家秀绝技 上电视准确“破案”',content:[
              ['img','1-1.jpg'],
              ['text','厦门晚报报道：'],
              ['text','（记者 王东城）2018 年.1月.13日在湖南卫视《快乐大本营》上，厦门笔迹学会终身荣誉会长金一贵和学会常务副会长兼秘书长许桂月受邀登台亮相，以字“破案”，找出了案件“主谋”。'],
              ['text','“目前国内仅有厦门成立了笔迹学会。”金一贵说。那么笔迹学的神奇之处在哪里？昨天，记者采访了金一贵。'],
              ['text','节目事先没有任何提示 厦门笔迹专家技惊四座','bold'],
              ['text','“节目组设置了一个剧情:‘主谋’指使同伙‘作案’，两人之间有书信往来。最后，节目组为了找出‘主谋’，请所有‘嫌疑人’按照书信内容现场书写，让我辨认。”金一贵说，这次节目录制事先没有任何提示，都是他和许桂月现场分析得出结论，而且按照湖南卫视的要求，将两人分析的过程大声表达出来，节目组还打出字幕。',],
              ['text','这期节目上，王凯、马天宇、王大陆、叶祖新等实力演员施展演技，让“案情”更加扑朔迷离。',],
              ['text','在节目组提供的书信中，金一贵注意到，其中的“麦”和“货”两个字，捺的写法一致，此外“取”“日”“上”三个字的竖画部分都有笔直的特点。加上字体的紧凑度、写法习惯等元素，金一贵和许桂月联手锁定了嫌疑人。虽然难度很大，但是最终结果显示，他们准确无误地找出了“主谋”马天宇，让在场嘉宾目瞪口呆。',],
              ['text','揭秘','bold'],
              ['text','笔迹分析有科学性','bold'],
              ['text','研究也需要大数据','bold'],
              ['text','“书写过程中不同的握笔、用笔方法，坐姿，放纸的位置等，会使书写者形成不同的行为，时间一长，行为模式将向心理模式转移。”金一贵说，笔迹学是一门科学，而不是单纯的主观臆测。',],
              ['text','金一贵在节目上说，按照国际统计惯例，每一个重要的笔迹特征至少需要500个例子来佐证，厦门则做到5000个例子，就像大数据一样，更加准确。',],
              ['text','金一贵告诉记者，他从小就喜欢将人的动作看成一个字，同时也喜欢将字看成人的形态。“我小时候，大约小学五六年级时，将家里的行书字帖上的行书字全部画成人的形态。现在想来，当时已经有笔迹分析的萌芽。”',],
              ['text','金一贵的笔迹分析有三大要素:压力、节奏和字体大小。压力，反映着书写者对外界的态度。通常来讲，写字力大且用力均衡的人，其独立处理问题的能力要高于一般人。节奏则反映了书写者本人的工作生活节奏。字体的大小与书写者对外界的拓展力度成正比。',],
              ['text','金一贵说，笔迹学的应用范围很广，从刑侦、文书司法鉴定、考古再到教育等领域，都具备较强参考借鉴价值，他本人有不少成功案例。',],
              ['text','观点','bold'],
              ['text','书写风格改变了','bold'],
              ['text','性格也会跟着变','bold'],
              ['text','金一贵曾从事美术和书法教学研究。他指导学生练字的时候，注意到有的家长、老师不加选择让孩子练字，有的学生虽然练字进步很快，但是内向的学生更加内向了，外向的学生更张扬了。',],
              ['text','金一贵尝试反过来补救，他让内向的学生先练习颜真卿的字体然后练习行书，因为颜真卿的字大气包容。他又让外向的练习欧阳询的字体，因为欧阳询的字很规矩、严谨。最后经过一番努力，字变人也变，练字的学生个性也跟着受影响。',],

            ]},
            // {
            //   id:2,time:'2018.03.22',title:'科学仪器为您揭开古玩艺术品的真伪！',content:[
            //     ['text','海峡导报报道：'],
            //     ['text','导报讯（记者 吴舒远）2018年3月21日下午，由海峡导报、福建省古玩商会厦门分会、北京垣钧文物鉴定技术研究院联合举办的鉴宝艺术沙龙在厦门顺天达拍卖有限公司举行。参加本次活动的有来自古玩界的藏友们和导报读者三十余名。'],
            //     ['text','活动现场，首先由福建省古玩商会厦门分会会长吴顺强分享了对古玩艺术品市场的现状及未来市场趋势的剖析。吴顺强会长以他二十几年收藏的经验，用其言简意赅且生动有趣的表达方式讲解了古玩的概念，剖析了艺术品在精神层面给人带来的益处等等。另外，记者首次在现场看到由北京垣钧文物鉴定技术研究院院长曾志胤通过刑侦手法科学仪器荧光检测仪来对艺术品的成分进行分析。比如，从书画的纸张纤维可框定其年代的大致范围。又比如，对某铜炉的检测，发现其含汞量高达8%，让人不寒而栗。古玩艺术品的鉴定由科学仪器与专家眼鉴相结合，无疑是给各位藏友一份前所未有的双重保障。'],
            //     ['text','本次沙龙是福建省古玩商会厦门分会换届后首场鉴宝沙龙活动，今后导报将联合福建省古玩商会厦门分会带您走进实验室，用最权威的方式，与您分享古玩艺术品的真伪之道。'],
            //   ],
            // },
            {
              id:2,time:'2020.06.28',title:'快看，历思“智慧禁毒”黑科技，上电视台啦~',content:[
                ['text','6.26国际禁毒日即将到来，历思一则“智慧禁毒”黑科技的新闻，登上福建省电视台新闻频道特别节目——《禁毒之战2020》，提前给大家进行了“热身”。'],
                ['img','3-1.png'],
                ['text','如今的“智慧禁毒”已渗透到禁毒工作的方方面面，“科技助力”是当前禁毒工作最核心的力量。在实际禁毒工作中，基于智慧禁毒的司法鉴定，已成为禁毒工作强大的科技助力。'],
                ['text','下面让我们一起来看看，历思“黑科技”加持“智慧禁毒”，是如何显神威的：'],
                ['text','点击观看完整视频：','bold'],
                ['a','https://v.qq.com/x/page/c3102l3f730.html','bold'],
                ['text','(“智慧禁毒”构筑新格局 高科技织起禁毒密网)','bold'],



              ]
            }
            // {id:2,time:'2020-05-25',content:'强边远山区毒品原植物遥感监测、无人机航测、巡山踏查，确保非法种植活动不反弹、无蔓延。同时，福建省组织开展吸毒人员排查管控专项行动，分类管控社会面吸毒人员，实现禁毒安保...',title:'2019年以来福建省共破获毒品犯罪案件3759起 ',img:['/2.png']},
            // {id:3,time:'2020-05-12',content:'以法治引领和保障高质量发展超越 ——访省司法厅党委书记、厅长邬勇雷 台海网9月4日讯 据福建日报报道   今年以来，福建省司法厅深入学习贯彻习近平总书记...',title:'访福建省司法厅党委书记、厅长邬勇雷：以法治引领和保障高质量发展超越',img:['/3.jpg']},
        ],
        kmProductData:[
            {title:'毒品新型检测平台',img:'/2.png'},
            {title:'智慧水质采样器',img:'/3.png'},
            {title:'物证箱',img:'/4.png'},
            {title:'水体便捷式取样装置',img:'/5.png'},
            {title:'在线水体毒品检测仪',img:'/6.png'},
        ],
        dutyActivityData:[
            {img:['/1-1.jpg','/1-2.jpg','/1-3.jpg',]},
            {img:['/2-1.jpg','/2-2.jpg','/2-3.jpg',]},
        ],
        developDevData:[
            {title:'检测室',img:'/1-1.jpg'},
            {title:'检测室',img:'/1-2.jpg'},
            {title:'检测室',img:'/1-3.jpg'},
            {title:'检测室',img:'/14.jpg'},
            {title:'检测室',img:'/1-5.jpg'},
            {title:'检测室',img:'/1-6.jpg'},
            {title:'检测室',img:'/1-7.jpg'},
            {title:'检测室',img:'/1-8.jpg'},
            {title:'检测室',img:'/1-9.jpg'},
            
        ],
        developData:[
            // {id:1,time:'',content:'完整的薪资构成体系，丰富的带薪假期，其他各项诱人福利。',title:'薪酬福利',img:['/4.png',],moreImg:['/3-1.png','/3-2.png']},
            {id:2,time:'',content:'历思集团设有多种培训渠道，提供员工职业发展。',title:'培训发展',img:['/4.jpg'],moreImg:['/4.png']},
        ],

    },
    mutations:{
        set_Module(state, payload){
            state.Module = payload
        },
        set_width(state, payload){
            state.width = payload
        },
        set_path(state, payload){
            state.path = payload
        }
    }
})
export default store
<template>
    <p class="p" :style="'text-indent:'+indent">
        <slot></slot>
    </p>
</template>
<script>
export default {
    props:{
        indent:{
            default:'2rem',
        }
    },
    name:'pg-col',
}
</script>
<style scoped>
.p{
    text-align:left;
    letter-spacing:2px;
    font-size:1rem;
    margin: 2rem 0;
}
</style>